import { AttributeObserver } from '@hotwired/stimulus';

export default class StimulusControllerResolver {
  constructor(application, resolverFn) {
    this.application = application;
    this.loadingControllers = new Set();
    this.controllerNamesCache = new WeakMap();
    this.resolverFn = resolverFn;

    this.loadStimulusControllers = this.loadStimulusControllers.bind(this);

    this.observer = new AttributeObserver(
      application.element,
      application.schema.controllerAttribute,
      {
        elementMatchedAttribute: this.loadStimulusControllers,
        elementAttributeValueChanged: this.loadStimulusControllers
      }
    );
  }

  start() {
    this.observer.start();
  }

  stop() {
    this.observer.stop();
  }

  static install(application, resolverFn) {
    const instance = new StimulusControllerResolver(application, resolverFn);
    instance.start();
    return instance;
  }

  loadStimulusControllers(element) {
    let controllerNames = this.controllerNamesCache.get(element);
    if (!controllerNames) {
      const attr = element.getAttribute('data-controller');
      if (!attr) return; // No controllers to load

      controllerNames = attr.split(/\s+/);
      this.controllerNamesCache.set(element, controllerNames);
    }

    // Load controllers in parallel
    Promise.all(controllerNames.map((name) => this.loadController(name))).catch(
      (err) => {
        console.error(`Failed to load controllers: ${err}`);
      }
    );
  }

  async loadController(controllerName) {
    if (
      this.loadingControllers.has(controllerName) ||
      this.application.router.modulesByIdentifier.has(controllerName)
    ) {
      return;
    }

    this.loadingControllers.add(controllerName);

    try {
      const controllerDefinition = await this.resolverFn(controllerName);

      if (controllerDefinition) {
        this.application.register(controllerName, controllerDefinition);
      }
    } catch (err) {
      console.error(`Failed to load controller "${controllerName}": ${err}`);
    } finally {
      this.loadingControllers.delete(controllerName);
    }
  }
}

export function createViteGlobResolver(...globResults) {
  const controllerLoaders = mapGlobKeysToIdentifiers(globResults);

  const resolverFn = async (controllerName) => {
    const loader = controllerLoaders.get(controllerName);

    if (process.env.NODE_ENV === 'development' && !loader) {
      console.warn(
        `Stimulus Controller Resolver can't resolve "${controllerName}". Available:`,
        Array.from(controllerLoaders.keys())
      );
      return;
    }

    if (!loader) return;

    return (await loader()).default;
  };

  return resolverFn;
}

export function mapGlobKeysToIdentifiers(globResults) {
  const map = new Map();

  for (const globResult of globResults) {
    for (const [key, controllerFn] of Object.entries(globResult)) {
      map.set(identifierForGlobKey(key), controllerFn);
    }
  }

  return map;
}

export const CONTROLLER_FILENAME_REGEX =
  /^(?:.*?(?:controllers|components)\/|\.?\.\/)?(.+)(?:[_-]controller\..+?)$/;

export function identifierForGlobKey(key) {
  const logicalName = (key.match(CONTROLLER_FILENAME_REGEX) || [])[1];
  if (logicalName) return logicalName.replace(/_/g, '-').replace(/\//g, '--');
}
